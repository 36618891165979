import React, { useState } from "react";

import LoanOfficerSearch from "../loan-officers/loan-officer-search";
import { useLanguageContext } from "../../contexts/language-context";

const PreApprovalWorkflow = (props) => {
  const isSpanish = useLanguageContext();

  const [showLOModal, setShowLOModal] = useState(false);
  const handleCloseLOModal = () => setShowLOModal(false);
  const handleShowLOModal = () => {
    setShowLOModal(true);
  };
  return (
    <section className="bg-blue-10">
      <LoanOfficerSearch isModalSearch={true} show={showLOModal} handleClose={handleCloseLOModal} preApproval={true} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-auto mb-3 mb-sm-0">
            <img src="/images/icons/pre-approved-icon.svg" alt="" width="112px" />
          </div>
          <div className="col">
            <h2>
              {isSpanish
                ? "Haga que su oferta se destaque con una aprobación previa de préstamo hipotecario*"
                : props.title}
            </h2>
            <h4>
              {isSpanish
                ? "Una aprobación previa le da la confianza de saber cuánta casa puede pagar. Nuestro programa de aprobación previa está verificado y les permite a los vendedores saber que usted es un comprador serio que puede calificar para la casa que le interesa."
                : props.text}
            </h4>
            <button onClick={handleShowLOModal} className="btn btn-primary mb-3" id="pre-approval-zipcode-modal-cta">
              {isSpanish ? "Inicie su aprobación previa" : "Start Your Pre-Approval"}
            </button>
            <p className="mb-0 text-sm text-muted" id="pre-approval-disclaimer">
              {isSpanish
                ? "*Las aprobaciones previas se basan en la verificación de crédito, ingresos y empleo."
                : "*WaFd Bank Pre-Approvals are based on verified credit, income, and employment verification."}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PreApprovalWorkflow;

PreApprovalWorkflow.defaultProps = {
  title: "Make Your Offer Stand Out with a Home Loan Pre-Approval*",
  text: "A verified Pre-Approval gives you the confidence of knowing how much house you can afford. Our Pre-Approval program is verified by our Underwriting Team, and lets sellers know you're a serious buyer who can qualify for the house you are interested in."
};
