import React from "react";
import PropTypes from "prop-types";

// Components
import GlobalLayout from "./global-layout";
import Header from "../header/header";
import MortgageNotificationBar from "../notifications/mortgage-notification-bar";
import Footer from "../footer/footer";

const SecondaryLanding = ({ children, footerBorder, showMortgageNotification, loanType }) => {
  return (
    <GlobalLayout>
      {showMortgageNotification && <MortgageNotificationBar loanType={loanType} />}
      <Header />
      <main id="main-content">{children}</main>
      {/* <Appointment /> */}
      <Footer footerBorder={footerBorder} />
    </GlobalLayout>
  );
};

SecondaryLanding.defaultProps = {
  footerBorder: true, // TODO: rename to "showFooterBorder"
  showMortgageNotification: false,
  loanType: "Home"
};

SecondaryLanding.propTypes = {
  children: PropTypes.node.isRequired,
  footerBorder: PropTypes.bool,
  showMortgageNotification: PropTypes.bool,
  loanType: PropTypes.string
};

export default SecondaryLanding;
