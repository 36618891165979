import React, { Fragment } from "react";
import ServiceStatesLinks from "../service-states/service-states-links";
import { useLanguageContext } from "../../contexts/language-context";
import { Link } from "gatsby";

const HelpSection = (props) => {
  const { statePage } = props;
  const serviceStateLinksData = {
    class: props.stateLinksClass,
    hasTexasLink: props.hasTexasLink,
    stateData: props.stateData
  };
  const isSpanish = useLanguageContext();
  /**
   * @typedef {( "AZ" | "ID" | "OR" | "WA" | "NM" | "UT" | "NV" | "TX") } StateCodeKeys
   */

  const cityPageLinks = {
    WA: [
      {
        cityName: "Seattle",
        cityUrl: "/locations/washington/seattle"
      },
      {
        cityName: "Bellingham",
        cityUrl: "/locations/washington/bellingham"
      },
      {
        cityName: "Everett",
        cityUrl: "/locations/washington/everett"
      },
      {
        cityName: "Bellevue",
        cityUrl: "/locations/washington/bellevue"
      },
      {
        cityName: "Bothell",
        cityUrl: "/locations/washington/bothell"
      },
      {
        cityName: "Kirkland",
        cityUrl: "/locations/washington/kirkland"
      }
    ],
    ID: [
      {
        cityName: "Boise",
        cityUrl: "/locations/idaho/boise"
      },
      {
        cityName: "Nampa",
        cityUrl: "/locations/idaho/nampa"
      }
    ],
    NV: [
      {
        cityName: "Las Vegas",
        cityUrl: "/locations/nevada/las-vegas"
      }
    ],
    UT: [
      {
        cityName: "Salt Lake City",
        cityUrl: "/locations/utah/salt-lake-city"
      }
    ],
    AZ: [
      {
        cityName: "Phoenix",
        cityUrl: "/locations/arizona/phoenix"
      },
      {
        cityName: "Tucson",
        cityUrl: "/locations/arizona/tucson"
      },
      {
        cityName: "Yuma",
        cityUrl: "/locations/arizona/yuma"
      },
      {
        cityName: "Mesa",
        cityUrl: "/locations/arizona/mesa"
      }
    ],
    NM: [
      {
        cityName: "Albuquerque",
        cityUrl: "/locations/new-mexico/albuquerque"
      },
      {
        cityName: "Santa Fe",
        cityUrl: "/locations/new-mexico/santa-fe"
      },
      {
        cityName: "Las Cruces",
        cityUrl: "/locations/new-mexico/las-cruces"
      },
      {
        cityName: "Roswell",
        cityUrl: "/locations/new-mexico/roswell"
      },
      {
        cityName: "Silver City",
        cityUrl: "/locations/new-mexico/silver-city"
      }
    ],
    OR: [
      {
        cityName: "Eugene",
        cityUrl: "/locations/oregon/eugene"
      },
      {
        cityName: "Portland",
        cityUrl: "/locations/oregon/portland"
      },
      {
        cityName: "Medford",
        cityUrl: "/locations/oregon/medford"
      },
      {
        cityName: "Bend",
        cityUrl: "/locations/oregon/bend"
      },
      {
        cityName: "Grants Pass",
        cityUrl: "/locations/oregon/grants-pass"
      },
      {
        cityName: "Klamath Falls",
        cityUrl: "/locations/oregon/klamath-falls"
      },
      {
        cityName: "Salem",
        cityUrl: "/locations/oregon/salem"
      }
    ],
    TX: [
      {
        cityName: "Dallas",
        cityUrl: "/locations/texas/dallas"
      },
      {
        cityName: "Austin",
        cityUrl: "/locations/texas/austin"
      }
    ]
  };
  return (
    <section id={props.id} className={props.sectionClass}>
      <div className="container">
        <h2>{isSpanish ? "Necesitas ayuda? Acércate a nosotros." : "Want more help? Come See Us."}</h2>
        <div className="row">
          <div className="col-lg-6">
            <p className="mb-lg-0" dangerouslySetInnerHTML={{ __html: props.text }}></p>
          </div>
          <div className="col-lg-6">
            {statePage ? (
              <>
                {cityPageLinks[statePage.StateCode].map((n, index) => (
                  <Fragment key={index}>
                    <Link id={`city-${index + 1}-link`} to={n.cityUrl}>
                      {n.cityName}
                    </Link>
                    {index === cityPageLinks[statePage.StateCode].length - 1 ? "" : ", "}{" "}
                  </Fragment>
                ))}{" "}
                {isSpanish ? "y " : "and "}
                <Link id="state-link" to={"/locations/" + statePage.stateData.Slug}>
                  {statePage.StateName}
                </Link>
              </>
            ) : (
              <ServiceStatesLinks {...serviceStateLinksData} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default HelpSection;

HelpSection.defaultProps = {
  text: "If you've done all your research but aren't sure a remodeling loan makes sense, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have.",
  stateLinksClass: "text-center",
  hasTexasLink: true,
  statePage: null,
  stateData: [
    {
      id: "arizona-link",
      enName: "Arizona",
      enUrl: "/locations/arizona",
      esName: "Arizona",
      esUrl: "/es/sucursales/arizona"
    },
    {
      id: "california-link",
      enName: "California",
      enUrl: "/locations/california",
      esName: "California",
      esUrl: "/es/sucursales/california"
    },
    {
      id: "idaho-link",
      enName: "Idaho",
      enUrl: "/locations/idaho",
      esName: "Idaho",
      esUrl: "/es/sucursales/idaho"
    },
    {
      id: "nevada-link",
      enName: "Nevada",
      enUrl: "/locations/nevada",
      esName: "Nevada",
      esUrl: "/es/sucursales/nevada"
    },
    {
      id: "new-mexico-link",
      enName: "New Mexico",
      enUrl: "/locations/new-mexico",
      esName: "Nuevo Mexico",
      esUrl: "/es/sucursales/nuevo-mexico"
    },
    {
      id: "oregon-link",
      enName: "Oregon",
      enUrl: "/locations/oregon",
      esName: "Oregon",
      esUrl: "/es/sucursales/oregon"
    },
    {
      id: "texas-link",
      enName: "Texas",
      enUrl: "/locations/texas",
      esName: "Texas",
      esUrl: "/es/sucursales/texas"
    },
    {
      id: "utah-link",
      enName: "Utah",
      enUrl: "/locations/utah",
      esName: "Utah",
      esUrl: "/es/sucursales/utah"
    },
    {
      id: "washington-link",
      enName: "Washington",
      enUrl: "/locations/washington",
      esName: "Washington",
      esUrl: "/es/sucursales/washington"
    }
  ]
};
